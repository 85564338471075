:root {
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-border-radius: 15px;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-inactive-color: rgba(0, 0, 0, 0.5);
  --swiper-pagination-bullet-inactive-opacity: 0.1;
}
/* 
.swiper-pagination {
  margin-bottom: 65px;
} */

/* @media screen and (max-width: 640px) {
  .swiper-pagination {
    margin-bottom: 50px;
  }
} */

.swiper-pagination-bullet {
  width: 11px;
  background: rgba(255, 255, 255, 0.6) !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 35px !important;
  background: #ffffff !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  bottom: 12px;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-inline: 5px !important;
}

@media screen and (max-width: 388px) {
  .swiper-pagination-bullet {
    width: 10px !important;
    background: rgba(255, 255, 255, 0.6) !important;
    opacity: 1;
    height: 4px !important;
  }

  .swiper-pagination-bullet-active {
    width: 18px !important;
    background: #ffffff !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin-inline: 3px !important;
  }
}
