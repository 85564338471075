div.sfc-main-container {
  background: transparent;
}
div.sfc-main-container {
  background-color: transparent;
}

div.sfc-main-container .main-title {
  color: #eeeeee;
}

div.sfc-main-container .form-radio-style.item-selected {
  color: #eeeeee;
  border: 0;
}

div.sfc-main-container .pm-details.show {
  background-color: transparent;
  border-radius: 0;
}

div.sfc-main-container .line-container {
  background-color: transparent;
  border-radius: 0;
}

div.sfc-main-container .getdcc {
  background-color: transparent;
  border-radius: 0;
}

/* div.sfc-main-container input[type="text"] {
  background-color: transparent;
  color: #eeeeee;
  border-radius: 6px;
} */

div.sfc-main-container .getdcc .converted_amount {
  background-color: transparent;
}

div.sfc-main-container .getdcc .dcc_select select {
  background-color: transparent;
  border: 1px solid #00becf;
}

div.sfc-main-container .getdcc span {
  color: #eeeeee;
}

div.sfc-main-container .getdcc label {
  color: #eeeeee;
}

div.sfc-main-container .sfc-cfa-button:hover {
  background-color: #02475e;
}

.container_for_checkout {
  margin: 0 auto;
  max-width: 750px;
  box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.2);
  padding: 40px 70px;
  text-align: left;
}
